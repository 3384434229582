.AllNewsWidget-module_news-list_9Gp5A__Main {
  content-visibility: auto;
  flex-direction: column;
  width: 300px;
  display: flex;
}

.AllNewsWidget-module_news-list__wrapper_8LfEK__Main {
  background-color: var(--bg-block);
  border-radius: 10px;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  display: flex;
}

.AllNewsWidget-module_news-list__heading--text_QPNeG__Main {
  color: var(--text-primary);
}

.AllNewsWidget-module_news-list__list_qKkgp__Main {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

@media (max-width: 660px) {
  .AllNewsWidget-module_news-list_9Gp5A__Main {
    width: auto;
  }

  .AllNewsWidget-module_news-list__wrapper_8LfEK__Main {
    border-radius: 10px;
  }
}

